import { appWithTranslation } from 'next-i18next';
import { Provider } from 'next-auth/client';
import PlausibleProvider from 'next-plausible';

import '../scss/theme.scss';

const MyApp = ({ Component, pageProps }) => {
  return (
    <PlausibleProvider domain="hyapp.de">
      <Provider session={pageProps.session}>
        <Component {...pageProps} />
      </Provider>
    </PlausibleProvider>
  );
};

export default appWithTranslation(MyApp);
